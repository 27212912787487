import { Box } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import MuiTooltip, { TooltipProps as MuiTooltipProps } from "@material-ui/core/Tooltip";
import { ClassNameMap } from "@material-ui/styles";
import clsx from "clsx";
import { FC, MouseEventHandler, useCallback } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  tooltip: {},
  tooltipDisabled: {
    display: "none",
  },
  titleWrapper: {},
}));

export type TooltipJSSClassKey = keyof ReturnType<typeof useStyles>;

export type TooltipProps = Omit<MuiTooltipProps, "classes"> & {
  className?: string;
  classes?: Partial<ClassNameMap<TooltipJSSClassKey>>;
  TooltipClasses?: MuiTooltipProps["classes"];
  disabled?: boolean;
  disableTooltip?: boolean;
  letEventsPropagate?: boolean;
};

export const Tooltip: FC<TooltipProps> = ({
  className,
  classes: extClasses,
  children,
  disabled,
  TooltipClasses,
  title,
  letEventsPropagate,
  ...rest
}) => {
  const classes = useStyles({ classes: extClasses });

  const stopProp = useCallback<MouseEventHandler<HTMLElement>>(
    (e) => !letEventsPropagate && e.stopPropagation(),
    [letEventsPropagate]
  );

  return (
    <MuiTooltip
      className={clsx(className, classes.root)}
      title={
        <Box className={classes.titleWrapper} onMouseDown={stopProp} onClick={stopProp} onMouseUp={stopProp}>
          {title}
        </Box>
      }
      classes={{
        ...TooltipClasses,
        tooltip: clsx(classes.tooltip, TooltipClasses?.tooltip, {
          [classes.tooltipDisabled]: disabled,
        }),
      }}
      arrow
      {...rest}
    >
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
